import 'react';
import React, { useState, useContext, createContext, useEffect } from 'react'
import { AccountDTO } from './models/Identity';
import { ApiIdentityRequest } from './api/makeRequest';
import accountsRequest from './api/accountsRequest';
import { set } from 'lodash';
import { sessionId } from './localStorage';
import { useAuth } from 'react-oidc-context';

interface globalStateWithUpdateData {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
  isAuthenticated: boolean,
  token: string | null,
  roles: string[],
  isPayrollOffice: boolean,
  notificationsUnread: number,
  users: AccountDTO[]

  setSidebarShow: React.Dispatch<React.SetStateAction<boolean>>
  setSidebarUnfold: React.Dispatch<React.SetStateAction<boolean>>
  setAsideShow: React.Dispatch<React.SetStateAction<boolean>>
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  setToken: React.Dispatch<React.SetStateAction<string | null>>
  setRoles: React.Dispatch<React.SetStateAction<string[]>>
  setIsPayrollOffice: React.Dispatch<React.SetStateAction<boolean>>
  setNotificationUnread: React.Dispatch<React.SetStateAction<number>>
  setUsers: React.Dispatch<React.SetStateAction<AccountDTO[]>>
}


const initialState: globalStateWithUpdateData = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'default',
  isAuthenticated: false,
  token: null,
  roles: [],
  isPayrollOffice: false,
  notificationsUnread: 0,
  users: [],

  setSidebarShow: () => {},
  setSidebarUnfold: () => {},
  setAsideShow: () => {},
  setIsAuthenticated: () => {},
  setToken: () => {},
  setRoles: () => {},
  setIsPayrollOffice: () => {},
  setNotificationUnread: () => {},
  setUsers: () => {}
};


interface IGlobalContextProps {
  children: React.ReactNode
}

const GlobalContext = createContext<globalStateWithUpdateData>(initialState)
export const useGlobalContext = () => useContext(GlobalContext)

export function GlobalContextWrapper(p: IGlobalContextProps) {
  const auth = useAuth();
  
  const [sidebarShow, setSidebarShow] = useState<boolean>(true)
  const [sidebarUnfoldable, setSidebarUnfold] = useState<boolean>(true)
  const [asideShow, setAsideShow] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [token, setToken]= useState<string | null>(null)
  const [roles, setRoles] = useState<string[]>([])
  const [isPayrollOffice, setIsPayrollOffice] = useState<boolean>(false);
  const [notificationsUnread, setNotificationUnread] = useState<number>(0)
  const [users, setUsers] = useState<AccountDTO[]>([])


  useEffect(() => {
    if (localStorage) {
      let token = localStorage.getItem(sessionId.token);
      let roles = localStorage.getItem(sessionId.roles);
  
      if (roles !== null && roles !== "") {
        let rolesArray = JSON.parse(roles);
        setRoles(rolesArray)
        console.log('OAuthWrapper: load roles to local storage')
      }
  
      if (token != null) {
        setToken(token)
        setIsAuthenticated(true)
        console.log('OAuthWrapper: load token to local storage')
      }
    }
  },[])

  // fetch all users from the tenant when the token is present
  useEffect(() => {
    console.log('trigger update token')
    if (auth != null && 
        auth.user != null && 
        auth.user?.access_token != null && 
        auth.user?.access_token != '') {
      accountsRequest(auth.user?.access_token)
        .GetUsers()
        .then(r => {
          setUsers(r)
        })

      accountsRequest(auth.user?.access_token)
        .GetMe()
        .then(r => {
          setRoles(r.Roles)
          setIsPayrollOffice(r.IsPayrollOffice)
          setToken(auth.user?.access_token ?? "")
          setIsAuthenticated(auth.user?.access_token != '' ? true : false)
        })
    }
  }, [auth?.user?.access_token])


  const gState: globalStateWithUpdateData = {
    sidebarShow,
    sidebarUnfoldable,
    asideShow,
    theme: 'default',
    isAuthenticated,
    token,
    roles,
    isPayrollOffice,
    notificationsUnread,
    users,

    setSidebarShow,
    setSidebarUnfold,
    setAsideShow,
    setIsAuthenticated,
    setToken,
    setRoles,
    setIsPayrollOffice,
    setNotificationUnread,
    setUsers
  }

  return (
    <GlobalContext.Provider value={gState}>{p.children}</GlobalContext.Provider>

  )
}