import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { GlobalContextWrapper, useGlobalContext } from './store'
import { sessionId } from './localStorage';
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context'
import { access } from 'fs'
import { User, WebStorageStateStore } from 'oidc-client-ts'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Logout = React.lazy(() => import('./views/Logout/Logout'))


interface IProps {
  children: JSX.Element
}
function RequireAuth(p: IProps) {
  let location = useLocation()
  const globalState = useGlobalContext()
  let auth = globalState.isAuthenticated

  if (!auth) {
    return (
      <Navigate to='/login' state={{ from: location }} replace />
    )
  }

  return p.children
}

// app to load and authenticate routes
function AppRoutes() {
  const auth = useAuth();
  const appState = useGlobalContext();

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      console.log('token expiring')
      auth.signinSilent();
      // if (confirm("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
      // }
    })
  }, [auth.events, auth.signinSilent]);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
      console.log('signing in silently on startup')
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);


  switch (auth.activeNavigator) {
    case "signinRedirect":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  // if (auth.isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated && appState.isAuthenticated) {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="*" element={
              <DefaultLayout />
            } />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;

}

function OauthWrapper(p: IProps) {
  const store = useGlobalContext();

  const onSigninCallback = (user: void | User) => {
    console.log(user);
    let rolesArray: any = [];
    let accesstoken: string = '';
    let isPayrollOffice: boolean = false;

    if (user?.access_token) {
      accesstoken = user?.access_token;
      if (localStorage) {
        localStorage.setItem(sessionId.token, accesstoken)
      }
      store.setToken(user.access_token)
    }

    console.log('token: ' + accesstoken)

    // try {
    //   let tokenDataString: string = atob(accesstoken.split('.')[1]);
    //   console.log(tokenDataString)
    //   var tokenObj: { [id: string]: any } = JSON.parse(tokenDataString)

    //   // check for roles
    //   rolesArray = tokenObj['role']
    //   if (Array.isArray(tokenObj['role']) == false) {
    //     rolesArray = [tokenObj['role']];
    //   }

    //   // check for payroll office
    //   isPayrollOffice = tokenObj['payrolloffice'] ? JSON.parse(tokenObj['payrolloffice']) : false;

    //   if (localStorage) {
    //     localStorage.setItem(sessionId.roles, JSON.stringify(rolesArray));
    //     localStorage.setItem(sessionId.payrollOffice, JSON.stringify(isPayrollOffice));
    //   }
    // }
    // catch {
    //   console.log("ERROR: parse roles in token")
    // }


    // store.updateData({
    //   roles: rolesArray,
    //   token: accesstoken,
    //   isPayrollOffice: isPayrollOffice,
    //   isAuthenticated: accesstoken != '' ? true : false
    // })



    // check for param **code** in location
    let locationSearch = window.location.search;
    if (locationSearch.indexOf("code") != -1) {
      window.history.replaceState
        ({},
          '',
          window.location.origin + window.location.hash)
    }
  }


  return (
    <AuthProvider
      client_id='js'
      scope='openid role offline_access profile.api guest.api blob.api joblisting.api notification.api IdentityServerApi'
      automaticSilentRenew={true}
      authority={process.env.REACT_APP_IDENTITY_URL as string}
      redirect_uri={window.location.origin}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      onSigninCallback={onSigninCallback}
    >
      {p.children}
    </AuthProvider>
  )
}


function App() {
  return (
    <OauthWrapper>
      <GlobalContextWrapper>
        <AppRoutes />
      </GlobalContextWrapper>
    </OauthWrapper>
  )
}

export default App
