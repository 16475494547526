import { ApiResult } from '../models/ApiResult'
import { IApiCrudRequest } from './interfaces';
import { apiBlob, apiConstants, apiGuests, apiIdentity, apiJob, apiNotification } from './constants'

export enum apiMethode {
    GET,
    POST,
    PUT,
    DELETE
}



export class ApiBaseRequest {
    // end with a tailling '/'
    baseUrl = apiConstants.baseUrl;
    token = '';
    // add a constructor
    constructor(token: string | undefined = '') {
        if (token == undefined || token == '') {
            let tokenProvider = process.env.REACT_APP_IDENTITY_URL as string;
            if (tokenProvider != undefined) {
                let key = `oidc.user:${tokenProvider}:js`;
                console.log('token not found in useAuth hook, trying to get from local storage');
                let userRaw = localStorage.getItem(key);
                let jsonObject = JSON.parse(userRaw as string);
                this.token = jsonObject.access_token;
            }
        }
        else {
            this.token = token;
        }
    }
    

    encodeQueryData(data: { [id: string]: string | number } | object): string {
        let array: { [id: string]: string | number | [] } = { ...data } as { [id: string]: string | number | [] };
        const ret = [];
        for (let d in array) {
            let value = array[d];

            // skip if empty
            if (value == undefined) continue;

            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value[i]))
                }
            } else {
                ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value))
            }

        }
        return ret.join("&");
    }

    setBaseUrl(newUrl: string) {
        this.baseUrl = newUrl;
    }

    async makeRequest<T>(
        url: string,
        type: apiMethode,
        body: any,
        formData: FormData | null = null): Promise<T> {
        const fetchData: RequestInit = {
            method: apiMethode[type],
            // credentials: "same-origin",
            headers: {
                "Content-Type": "application/json"
            },
            body: null
        }

        // if token is present add as header
        // var appStore = store.getState();
        if (this.token != '' && this.token != undefined) {
            fetchData.headers = {
                "Authorization": "Bearer " + this.token
            }
        }

        // set bdoy
        if (formData != null) {
            fetchData.body = formData;
        }

        if (body !== undefined && body !== null) {
            let headers: any = { ...fetchData.headers }
            headers['Content-Type'] = "application/json";

            fetchData.headers = headers;
            fetchData.body = JSON.stringify(body);
        }

        // start fetch
        console.log('Api request: ' + this.baseUrl + url)

        return fetch(this.baseUrl + url, fetchData)
            .then(r => {

                if (r.ok === false && (r.status == 403 || r.status == 401)) {
                    console.log("API: not allowed api request")
                    // store.dispatch({ type: "set", isAuthenticated: false, token: null });
                    // if (localStorage) {
                    //     localStorage.removeItem(sessionId.token);
                    // }
                    // window.location.reload();
                }

                if (r.status == 404) {
                    throw 'Not found';
                }

                if(r.status == 500) {
                    throw "Problem"
                }

                return r.json() as Promise<T>
            })
            .catch((error) => {
                throw (error)
            })

    }

}

export class ApiCrudRequest<T> extends ApiBaseRequest {
    config: IApiCrudRequest = {} as IApiCrudRequest

    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(token);
        this.config = init;
    }

    UrlGet(id: number): string {
        return this.config.url.Get + '/' + id;
    }
    UrlDelete(id: number): string {
        if (this.config.url.other != undefined) {
            return this.config.url.other["Delete"] + '/' + id;
        }
        return ''
    }

    GetAll(): Promise<T[]> {
        return this.makeRequest(this.config.url.Get, apiMethode.GET, null)
    }
    Get(id: number): Promise<T> {
        return this.makeRequest(this.UrlGet(id), apiMethode.GET, null)
    }
    Add(data: T): Promise<ApiResult> {
        let url = '';
        if (this.config.url.other != undefined) {
            url = this.config.url.other["Add"];
        }
        return this.makeRequest(url, apiMethode.POST, data)
    }
    Update(data: T): Promise<ApiResult> {
        let url = '';
        if (this.config.url.other != undefined) {
            url = this.config.url.other["Update"];
        }
        return this.makeRequest(url, apiMethode.POST, data)
    }
    Delete(id: number): Promise<ApiResult> {
        return this.makeRequest(this.UrlDelete(id), apiMethode.POST, null);
    }

}

// current api base class
export class ApiRESTRequest<T> extends ApiBaseRequest {
    config: IApiCrudRequest = {} as IApiCrudRequest

    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(token);
        this.config = init;
    }

    UrlGet(id: number): string {
        return this.config.url.Get + '/' + id;
    }
    UrlDelete(id: number): string {
        return this.config.url.Get + '/' + id;
    }

    GetAll(): Promise<T[]> {
        return this.makeRequest(this.config.url.Get, apiMethode.GET, null)
    }
    Get(id: number): Promise<T> {
        return this.makeRequest(this.UrlGet(id), apiMethode.GET, null)
    }
    Add(data: T): Promise<ApiResult> {
        return this.makeRequest(this.config.url.Get, apiMethode.POST, data)
    }
    Update(data: T): Promise<ApiResult> {
        return this.makeRequest(this.config.url.Get, apiMethode.PUT, data)
    }
    Delete(id: number): Promise<ApiResult> {
        return this.makeRequest(this.UrlDelete(id), apiMethode.DELETE, null);
    }

    /**
     * adds new parts to the base url
     * @param parts part to be added to the base url
     * @returns the base url (default get) with the parts joined by slash /
     */
    CombinePath(parts: string[]) {
        let base = this.config.url.Get;
        base = base.replaceAll('/', '');

        let url: string[] = [];
        url.push(base);

        for (let i = 0; i < parts.length; i++) {
            if (parts[i] != null)
                url.push(parts[i]);
        }

        return url.join('/');
    }
}

export class ApiGuestRequest<T> extends ApiRESTRequest<T> {
    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(init, token);
        // overwrite the baseURL for new api endpoint
        this.setBaseUrl(apiGuests.baseUrl)
    }
}

export class ApiBlobRequest<T> extends ApiRESTRequest<T> {
    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(init, token);
        // overwrite the baseURL for new api endpoint
        this.setBaseUrl(apiBlob.baseUrl)
    }
}

export class ApiJobRequest<T> extends ApiRESTRequest<T> {
    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(init, token);
        // overwrite the baseURL for new api endpoint
        this.setBaseUrl(apiJob.baseUrl)
    }
}

export class ApiNotifictionRequest<T> extends ApiRESTRequest<T> {
    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(init, token);
        // overwrite the baseURL for new api endpoint
        this.setBaseUrl(apiNotification.baseUrl)
    }
}

export class ApiIdentityRequest<T> extends ApiRESTRequest<T> {
    constructor(init: IApiCrudRequest, token: string | undefined) {
        super(init, token);
        // overwrite the baseURL for new api endpoint
        this.setBaseUrl(apiIdentity.baseUrl)
    }
}