import { AccountDTO, PasswordUpdateDTO, PersonalDTO, RolesAddDTO, RolesDTO, UserRightsAddDTO } from "../models/Identity";
import { IApiCrudRequest, IUrlAction } from "./interfaces";
import { ApiIdentityRequest, apiMethode } from "./makeRequest";


const url: IUrlAction = {
    Get: "Accounts"
}

const config: IApiCrudRequest = {
    url: url
}

class AccountsRequest extends ApiIdentityRequest<AccountDTO> {
    constructor(config: IApiCrudRequest, token: string | undefined) {
        super(config, token);
    }

    GetMe = async (): Promise<PersonalDTO> => {
        let url = this.CombinePath(['me']);
        return await this.makeRequest<PersonalDTO>(url, apiMethode.GET, null);
    }

    GetUsers = async ():Promise<AccountDTO[]> => {
        let url = this.CombinePath(['users']);
        return await this.makeRequest<AccountDTO[]>(url, apiMethode.GET, null);
    }

    GetRoles = async (): Promise<RolesDTO[]> => {
        let url = this.CombinePath(['roles']);
        return await this.makeRequest<RolesDTO[]>(url, apiMethode.GET, null);
    }

    AddRole = async (newRole: RolesAddDTO): Promise<void> => {
        let url = this.CombinePath(['addrole']);
        return await this.makeRequest<void>(url, apiMethode.POST, newRole);
    }

    RemoveRole = async (newRole: RolesAddDTO): Promise<void> => {
        let url = this.CombinePath(['removerole']);
        return await this.makeRequest<void>(url, apiMethode.POST, newRole);
    }
    AddUserRight = async (newRight: UserRightsAddDTO): Promise<void> => {
        let url = this.CombinePath(['adduserright']);
        return await this.makeRequest<void>(url, apiMethode.POST, newRight);
    }
    RemoveUserRight = async (newRight: UserRightsAddDTO): Promise<void> => {
        let url = this.CombinePath(['removeuserright']);
        return await this.makeRequest<void>(url, apiMethode.POST, newRight);
    }
    SetNewPassword = async (newPassword: PasswordUpdateDTO): Promise<void> => {
        let url = this.CombinePath(['setpassword']);
        return await this.makeRequest<void>(url, apiMethode.POST, newPassword);
    }
}

const accountsRequest = (token: string| undefined) =>  new AccountsRequest(config, token);
export default accountsRequest;