export const oAuthConstants = {
    oauth: (process.env.REACT_APP_IDENTITY_URL)+ "/connect/token",
    //clientId: "js",
    //clientSecret: "vlx5tsetgsvp2n81"
}

export const apiConstants = {
    baseUrl: (process.env.REACT_APP_PROFILE_API)+"/hr/",
    baseUrlApi: (process.env.REACT_APP_PROFILE_API)+"/api/",
    baseUrlOData: (process.env.REACT_APP_PROFILE_API)+"/odata/"
}

export const apiGuests = {
    baseUrl: (process.env.REACT_APP_GUEST_API)+'/'
}

export const apiIdentity = {
    baseUrl: (process.env.REACT_APP_IDENTITY_URL)+'/api/'
}

export const apiBlob = {
    baseUrl : (process.env.REACT_APP_BLOB_API)+'/'
}

export const apiJob = {
    baseUrl : (process.env.REACT_APP_JOB_API)+'/'
}

export const apiJobLP = process.env.REACT_APP_JOBPUBLIC_API+'/'

export const apiNotification = {
    baseUrl: (process.env.REACT_APP_NOTIFICATION_API)+'/'
}
